

























































import BaseButton from '@/components/base/BaseButton.vue';
import { useApi } from '@/composables/useApi';
import { useAuthStore } from '@/composables/useAuthStore';
import { useConfirmation } from '@/composables/useConfirmation/useConfirmation';
import { useLoader } from '@/composables/useLoader';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { formatDate } from '@/utils/date';
import PaymentMethods from './components/PaymentMethods.vue';

export default defineComponent({
  components: { BaseButton, PaymentMethods },
  name: 'ProfileBillingPage',
  setup() {
    const { isMember, refreshState, user } = useAuthStore();
    const editing = ref(false);
    const { loading, withLoader } = useLoader();
    const { confirmation } = useConfirmation();
    const { $delete, post } = useApi();

    onMounted(async () => {
      await refreshState();
    });

    const turnOffRenew = async () =>
      withLoader(async () => {
        const confirmed = await confirmation(
          'Are you sure you want to turn off auto-renew? You will no longer be able to book resources after the end of your billing period.'
        );

        if (confirmed) {
          await $delete('/subscription');
          await refreshState();
          editing.value = false;
        }
      });

    const turnOnRenew = async () =>
      withLoader(async () => {
        const confirmed = await confirmation(
          'Are you sure you want to turn on auto-renew? You will be charged automatically at the end of your billing period.'
        );

        if (confirmed) {
          await post('/subscription/resume', {});
          await refreshState();
          editing.value = false;
        }
      });

    const isGeneralPublic = user.value.data.customAttributes.account_type == 'General_Public';

    return {
      isMember,
      routeNames,
      user,
      formatDate,
      editing,
      turnOffRenew,
      turnOnRenew,
      loading,
      isGeneralPublic
    };
  },
});
